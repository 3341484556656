import React, {useState, useEffect, useMemo} from 'react';
import Box from '@mui/joy/Box'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'
import {faSquareCaretLeft, faSquareCaretRight} from '@fortawesome/sharp-duotone-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom'

export interface INavPage {
    name: string
    path: string
}

export interface INavCarousel{
    pages: INavPage[]
}

const NavCarousel: React.FC<INavCarousel> = (props: INavCarousel) => {
    const navigate = useNavigate()
    const baseSwap: boolean = false;
    const pages = useMemo(() => { return props.pages}, [props.pages]);
    const [curPage, setCurPage] = useState<number>(0);
    const [leftArrowSwap, setLeftArrowSwap] = useState<boolean>(baseSwap);
    const [rightArrowSwap, setRightArrowSwap] = useState<boolean>(baseSwap);

    const getDisplayName = () => {
        return pages[curPage % pages.length].name
    }

    const navArrowClicked = (op: string) => {
        let newVal: number = curPage + (op === '-' ? -1 : 1);
        newVal = (newVal < 0 ? pages.length - 1 : newVal % pages.length);
        setCurPage(newVal);
    }

    useEffect(() => {
        navigate(pages[curPage].path)
    }, [curPage, navigate, pages])

    const navArrowHover = (op : string) => {
        if (op === '-') {
            setLeftArrowSwap(!leftArrowSwap);
            return;
        }
        setRightArrowSwap(!rightArrowSwap);
    }

    return (<Box sx={{
        width: '50%',
        mx: {xs: 0, md: -4, lg: -7},
        my: -1,
    }}>
        <Box sx={{
           display: 'grid',
           gridTemplateColumns: `repeat(12, 1fr)`,
           width: {xs: '100%', md: '100%'},
        }}>
            <Box
                sx={{
                    gridColumn: {xs: 1, md: 2},
                }}
            >
                <Link
                    variant="plain"
                    onClick={() => {navArrowClicked("-");}}
                    onMouseEnter={() => {navArrowHover("-");}}
                    onMouseLeave={() => {navArrowHover("-");}}
                >
                    <FontAwesomeIcon icon={faSquareCaretLeft} swapOpacity={leftArrowSwap} height="100%"/>
                </Link>
            </Box>
            <Box sx={{
                display: 'grid',
                gridColumnStart: {xs: 3, md: 3},
                gridColumnEnd: {xs: 5, md: 6},
                width: '100%',
                textAlign: 'center',
            }}>
                <Typography component="h5" color="primary">{getDisplayName()}</Typography>
            </Box>
            <Box sx={{
                gridColumn: {xs: 7, md: 7},
            }}>
                <Link
                    variant="plain"
                    onClick={() => {navArrowClicked("+");}}
                    onMouseEnter={() => {navArrowHover("+");}}
                    onMouseLeave={() => {navArrowHover("+");}}
                >
                    <FontAwesomeIcon icon={faSquareCaretRight} swapOpacity={rightArrowSwap} height="100%"/>
                </Link>
            </Box>
        </Box>
    </Box>)
}

export default NavCarousel;