import React from 'react'

import { AspectRatio } from '@mui/joy'
import Box from '@mui/joy/Box'
import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import CardOverflow from '@mui/joy/CardOverflow'
import Typography from '@mui/joy/Typography'
import { faPerson } from '@fortawesome/sharp-duotone-solid-svg-icons/faPerson';
import { faLightbulb } from '@fortawesome/sharp-duotone-solid-svg-icons/faLightbulb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface INewsItem {
        id: number;
        title: string;
        excerpt: string;
        callout: string;
        published: string;
        category: string;
    };

const NewsItem: React.FC<INewsItem> = (item: INewsItem, callout: string) => {
    return (
          <Card
          sx={{
              borderRadius: 0,
              minWidth: '50%',
              maxWidth: '100%',
          }}>
              <CardContent>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }}>
                  <Typography level="body-xs">{item.callout}</Typography>
                  <Typography level="body-xs"></Typography>
                  <Typography
                      level="body-xs"
                      sx={{
                          textAlign: "right",
                          textDecoration: "underline",
                          textDecorationStyle: "dashed",
                      }}>{item.published.replace(/T.*$/, '')}</Typography>
                </Box>
                  <Typography level="title-lg">{item.title}</Typography>
              </CardContent>
              <Card
              orientation="horizontal"
              size="sm"
              sx={{ bgcolor: 'background.surface', borderRadius: 0, mb: 1, border: 'none', boxShadow: 'none' }}
              >
                  <CardOverflow>
                      <AspectRatio
                      ratio="1"
                      sx={{ minWidth: 70, '& img[data-first-child]': { p: 1.5 } }}
                      >
                          {(item.category === "personal" && <FontAwesomeIcon icon={faPerson} />) || (<FontAwesomeIcon icon={faLightbulb} />)}
                      </AspectRatio>
                  </CardOverflow>
                  <CardContent>
                      <Typography level="body-sm">{item.excerpt}</Typography>
                  </CardContent>
              </Card>
          </Card>);

}

export default NewsItem;
