import React, { useCallback, useEffect, useState}  from 'react'
import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet'
import NewsItem, {INewsItem} from './Item'
import {Link} from "@mui/joy";
import NewsArticle from "./Article";

export interface INewsFeed{}

const NewsFeed: React.FC<INewsFeed> = () => {
    const fetchHost = `${process.env.REACT_APP_DEV_API_HOST}`
    const newsPath = `${process.env.REACT_APP_DEV_NEWS_PATH}`
    const baseItemSize = 1;
    const baseReaderSize = 2;
    interface INewsFeed {
        loaded: boolean;
        items: INewsItem[];
    }
    const callouts : string[] = [ "Extra! Extra!", "Read All About It!", "Never Before Seen!", "Breaking!", "Fresh off the presses!" ];
    const [newsFeed, setNewsFeed] = useState<INewsFeed>({loaded: false, items: []});
    const [readerVisible, setReaderVisible] = useState<boolean>(false);
    const [articleID, setArticleID] = useState<number>(-1);

    const fetchNewsFeed = useCallback(async () => {
        const res : Response = await fetch(`${fetchHost}${newsPath}`);
        const items : INewsItem[] = await res.json()
        const list : INewsFeed = {loaded: true, items: items}
        setReaderVisible(false);
        setNewsFeed(list);
    }, [fetchHost, newsPath])

    const toggleReader = function() {
        setReaderVisible(!readerVisible);
    }

    const chooseArticle = function(id: number) {
        if (articleID === id && readerVisible) {
            setArticleID(-1);
            toggleReader();
            return;
        }
        setArticleID(id);
        if (!readerVisible) {
            toggleReader();
        }
    }

    useEffect(() => {
        fetchNewsFeed();
    }, [fetchNewsFeed]);

    return(
      <Sheet
          sx={{
              position: 'absolute',
              overflowY: 'scroll',
              width: '95%',
              maxWidth: '95%',
              height: '80%',
              maxHeight: '80%',
              mx: 'auto',
              my: 'auto',
              py: 3,
              px: 2,
              top: '18%',
              left: '1.6%',
              bottom: '6%',
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: `repeat(1, 1fr)`,
              flexDirection: 'column',
              gap: 1,
          }}
      >
          <Sheet
              sx={{
                  gridRow: `1`,
                  gridColumn: `span ${baseItemSize + baseReaderSize * (!readerVisible ? 1 : 0)}`,
                  gridTemplateRows: `repeat(4, 1fr)`,
                  flexDirection: 'row',
                  gap: 1,
                  width: '100%',
              }}
              variant="outlined">
              {newsFeed.loaded && newsFeed.items.map( (item, index) => {
                      item.callout = callouts[index % callouts.length];
                      return (
                          <Link key={item.id} overlay onClick={function() { chooseArticle(item.id)}}
                                sx={{
                                    width: '100%',
                                    gridRow: `${index + 1}`
                                }}>
                              <Box sx={{
                                  width: '100%'
                              }}>
                                  <NewsItem {...item} />
                              </Box>
                          </Link>
                      )
                  }
              )}
          </Sheet>
          {readerVisible && (<Box
              sx={{
                  gridRow: `1`,
                  gridColumn: `span ${baseReaderSize * (readerVisible ? 1 : 0)}`,
                  height: '100%',
              }}>
              <NewsArticle id={articleID} newsPath={`${fetchHost}${newsPath}`} />
          </Box>)}
      </Sheet>
    );
}

export default NewsFeed;
