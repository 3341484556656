import React, {useState, useEffect} from 'react'
import Sheet from '@mui/joy/Sheet'
import Chip from '@mui/joy/Chip'
import {ISkillData} from './SkillBoard'

export interface ISkillTags {
    skill: ISkillData
}

const SkillTags: React.FC<ISkillTags> = (props: ISkillTags) => {
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        setTags(props.skill.tags)
    }, [props])

    return (
        <Sheet
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                borderRadius: 5,
            }}>
            {tags.map((value, index) => { return (<Chip key={index} color="neutral" size="sm" variant="outlined">{value}</Chip>)})}
        </Sheet>
    );
};

export default SkillTags;
