import React from 'react'
import { CssVarsProvider } from '@mui/joy/styles'

import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import NewsFeed from './components/news/Feed'
import ProfileView from './components/profile/ProfileView'
import SkillView from './components/skills/SkillView'

import {
    Routes,
    Route,
} from "react-router-dom";
function App() {

  return (
    <CssVarsProvider>
      <Header />
        <Routes>
            <Route key="profile" path="/" element={<ProfileView />} />
            <Route key="news" path="news" element={<NewsFeed />} />
            <Route key="skills" path="skills" element={<SkillView />} />
        </Routes>
      <Footer />
    </CssVarsProvider>
  );
}

export default App;
