import React, { useCallback, useEffect, useState} from 'react'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Sheet from '@mui/joy/Sheet'
import SkillCard from "./SkillCard";
import FilterBar from "./FilterBar";
import {JuiMarkdown} from "jui-markdown";

export interface ISkillOverview {
    items: Map<string, ISkillData>
    count: number
}

export interface ISkillData {
    key: string
    items: Map<string, ISkillData>
    alias: string
    selfRating: number
    description: string
    tags: string[]
    count: number
}

export interface ISkillBoard{
    setVisibleSkills:  React.Dispatch<React.SetStateAction<number>>
}

const SkillBoard: React.FC<ISkillBoard> = (props:  ISkillBoard) => {
    const fetchHost = `${process.env.REACT_APP_DEV_API_HOST}`
    const skillsPath = `${process.env.REACT_APP_DEV_SKILLS_PATH}`

    const [overview, setOverview] = useState<ISkillOverview>({count: 0, items: new Map<string, ISkillData>() })
    const [skills, setSkills] = useState<ISkillData[]>([]);
    const [readerVisible, setReaderVisible] = useState<boolean>(false);
    const [skillID, setSkillID] = useState<string>("");
    const [skillDescription, setSkillDescription] = useState<ISkillDescription>({text: ""});

    useEffect(() => {
        if (overview.count <= 0) {
            return; // no skills.
        }
        const skills = new Map(Object.entries(overview.items));
        const extractSkillsList = (items: Map<string, ISkillData>): ISkillData[]  => {
            let skd: ISkillData[] = [];
            items.forEach((value, key) => {
                if (value.selfRating > 0) {
                    skd.push(value)
                }
                if (value.items) {
                    let children: ISkillData[] = extractSkillsList(new Map(Object.entries(value.items)));
                    children.forEach((value1: ISkillData) => {
                        if (value1.selfRating > 0) {
                            value1.key = value.key + "." + value1.key;
                            skd.push(value1);
                        }
                    })
                }
            })
            return skd;
        }

        let skd: ISkillData[] = extractSkillsList(skills);
        skd.sort(sortSkillsBySelfRating)
        setSkills(skd);
    }, [overview]);

    const sortSkillsBySelfRating = function(a: ISkillData, b: ISkillData): number {
        if (a.selfRating > b.selfRating) {
            return -1;
        } else if (a.selfRating === b.selfRating) {
            return 0;
        }
        return 1;
    }

    interface ISkillDescription {
        text: string
    }

    useEffect(() => {
        (async () => {
            if (skillID === "") {
                setReaderVisible(false);
                return
            }
            let addressString: string = fetchHost + ((skillsPath[0] !== '/') ? "/" : "") + skillsPath + '/' + skillID
            const res = await fetch(addressString)
            const desc: ISkillDescription = await res.json()
            setSkillDescription(desc)
            setReaderVisible(true)
        })();
        return () => {}
    }, [skillID, readerVisible, skillsPath, fetchHost])

    const {setVisibleSkills} = props;

    const fetchSkillsOverview = useCallback(async () => {
        let addressString: string = fetchHost + ((skillsPath[0] !== '/') ? "/" : "") + skillsPath
        const res: Response = await fetch(addressString)
        const _overview :ISkillOverview = await res.json()
        setOverview(_overview);
        setVisibleSkills(_overview.count);
    }, [setVisibleSkills, skillsPath, fetchHost])

    useEffect(() => {
        fetchSkillsOverview();
    }, [fetchSkillsOverview]);

    return (<Sheet
        sx={{
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <FilterBar />
        <Sheet
            sx={{
                display: 'grid',
                gridTemplateColumns: {md: `repeat(3, 1fr)`, xs: 2},
            }}
        variant="outlined">
            {overview.count > 0 && skills.map((item: ISkillData, index: number) => {
                return (
                    <SkillCard key={index} skill={item} setSkillID={setSkillID} />
                )
            })}
        </Sheet>
        {readerVisible && (<Modal open={readerVisible} onClose={() => {setReaderVisible(false); setSkillID("");}}><ModalDialog sx={{ overflowY: 'scroll', flexGrow: 1, flexShrink: 0 }}><ModalClose /><JuiMarkdown>{skillDescription.text}</JuiMarkdown></ModalDialog></Modal>)}
    </Sheet>)
}

export default SkillBoard;