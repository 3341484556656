import React from 'react';
import Sheet  from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'

import NavCarousel, {INavPage} from '../nav-carousel/NavCarousel'

function Header() {

    const pages:INavPage[] = [{name: 'Profile', path: '/'}, {name: 'News', path: '/news'}, {name: 'Skills', path: '/skills'}];

    return (
        <Sheet
            sx={{
                position: 'absolute',
                left: '1.6%',
                top: 0,
                width: '95%',
                maxWidth: '95%',
                height: '7%',
                py: 2,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 'lg',
                boxShadow: 'md',
            }}
            variant="outlined">
            <Typography level="h2" component="h2" sx={{
            }}>
                Daniel Taylor
            </Typography>
            <NavCarousel pages={pages} />
        </Sheet>
    );
}

export default Header;
