import React from 'react'
import Box from '@mui/joy/Box'
import Card from '@mui/joy/Card'
import Divider from '@mui/joy/Divider'
import Stack from '@mui/joy/Stack'
import Tooltip from '@mui/joy/Tooltip'
import Typography from '@mui/joy/Typography'
import SelfRating from './SelfRating'
import {ISkillData} from './SkillBoard'
import SkillCategories from './SkillCategories'
import SkillTags from './SkillTags'

export interface ISkillCard {
    skill: ISkillData
    setSkillID:  React.Dispatch<React.SetStateAction<string>>
}

const SkillCard: React.FC<ISkillCard> = (card: ISkillCard) => {
    const extractSkillName = (item: ISkillData): string => {
        if (item.alias && item.alias.length > 0) {
            return item.alias
        }
        let segs: string[] = item.key.split(".");
        let name: string = segs.pop() || "<none>";
        name = name[0].toUpperCase() + name.substring(1).toLowerCase();
        return name;
    }
    return (
        <Tooltip arrow title={card.skill.description}>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 0,
                    flexBasis: '33%',
                }}
                onClick={() => {card.setSkillID(card.skill.key);}}
            >
                <SelfRating skill={card.skill} />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    spacing: 1,
                }}>
                    <Typography key="h3" component="h3">
                        {extractSkillName(card.skill)}
                    </Typography>
                    <SkillCategories skill={card.skill} />
                    <Divider sx={{ my: 1, width: '100%', gridColumnStart: 1, gridColumnEnd: 12,}} />
                    <SkillTags skill={card.skill} />
                </Box>
                <Stack direction="row-reverse"
                sx={{
                    flexGrow: 1,
                    flexShrink: 0,
                }}>
                <Stack direction="column-reverse">
                    <Typography fontSize="xs" sx={{
                        textAlign: "right",
                        textDecoration: "underline",
                        textDecorationStyle: "double",
                    }}>
                        more...
                    </Typography>
                </Stack>
                </Stack>
            </Card>
        </Tooltip>
    );
}

export default SkillCard;