import React, {useState} from 'react';
import Badge from '@mui/joy/Badge'
import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import SkillBoard from "./SkillBoard";

export interface ISkillView{}

const SkillView: React.FC<ISkillView> = () => {
    const [visibleSkills, setVisibleSkills] = useState<number>(-1);
    return(
        <Sheet
            sx={{
                position: 'absolute',
                overflowY: 'scroll',
                width: '96%',
                maxWidth: '96%',
                height: '80%',
                mexHeight: '80%',
                mx: 'auto',
                my: 'auto',
                py: 3,
                px: 2,
                top: '18%',
                left: '1.6%',
                bottom: '7%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}>
            <Box sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                top: 0,
                bottom: '5%',
            }}>
                {visibleSkills >= 0 && (
                    <Badge badgeContent={visibleSkills} variant="soft">
                        <Typography level="h3" component="h3">
                            Skill, Abilities & Capabilities
                        </Typography>
                    </Badge>
                )}
                {visibleSkills < 0 && (
                    <Typography level="h3" component="h3">
                        Skill, Abilities & Capabilities
                    </Typography>
                )}
            </Box>
            <Sheet sx={{
                gridColumn: `span 2`,
                gridRow: `2`,
                height: '95%',
            }}>
                <SkillBoard setVisibleSkills={setVisibleSkills}/>
            </Sheet>
        </Sheet>
    );
};

export default SkillView;