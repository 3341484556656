import React from 'react'
import Box from '@mui/joy/Box'
import Chip from '@mui/joy/Chip'
import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import { faFilters } from '@fortawesome/sharp-duotone-solid-svg-icons/faFilters';
import { faCircleSortDown } from '@fortawesome/sharp-duotone-solid-svg-icons/faCircleSortDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IFilterBar{}

const FilterBar: React.FC<IFilterBar> = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
        }}>
            <FontAwesomeIcon icon={faFilters} style={{color: "#bed1ee"}} />
            <Sheet sx={{width: '1%',}}></Sheet>
            <Sheet sx={{
                backgroundColor: 'aliceblue',
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: '10%',
            }}
           variant="soft">
            <Chip
                color="success"
                variant="soft">
                All
            </Chip>
            </Sheet>
            <Chip color="neutral"><Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>

                <Typography startDecorator={<FontAwesomeIcon icon={faCircleSortDown} />}>Self Rating</Typography>
            </Box>
            </Chip>
        </Box>
    );
}

export default FilterBar;
