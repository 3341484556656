import React from 'react';
import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import {faHeadSideGear} from "@fortawesome/sharp-duotone-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {JuiMarkdown} from "jui-markdown";

export interface IProfileView{}

const ProfileView: React.FC<IProfileView> = () => {
    return(
        <Sheet
            sx={{
                position: 'absolute',
                overflowY: 'scroll',
                overflowX: 'hidden',
                width: '96%',
                maxWidth: '96%',
                height: '80%',
                mexHeight: '80%',
                mx: 'auto',
                my: 'auto',
                py: 3,
                px: 2,
                top: '18%',
                left: '1.6%',
                bottom: '7%',
                display: 'grid',
                flexGrow: 1,
                gridTemplateColumns: `repeat(11, 1fr)`,
                gridTemplateRows: `repeat(6, 1fr)`,
            }}>
            <Box sx={{
                display: { xs: 'none', sm: 'block' },
                gridColumnStart: `1`,
                gridColumnEnd: `2`,
                gridRowStart: `1`,
                gridRowEnd: `1`,
            }}>
                <FontAwesomeIcon icon={faHeadSideGear} size="2xl" color="#bed1ee" style={{height:"95%"}}/>
            </Box>
            <Box sx={{
                gridColumnStart: {xs: 1, sm: 2},
                gridColumnEnd: {xs: 2, sm: 3},
                gridRowStart: `1`,
                gridRowEnd: `1`,
                display: 'flex',
                direction: 'row',
            }}>
                <Stack direction="column-reverse" sx={{ width: '100%', }}>
                    <Typography key="h5exp" component="h5" textAlign="right">Expertise</Typography>
                    <Typography key="h5pos" component="h5" textAlign="right">Position</Typography>
                    <Typography key="h5nm" component="h5" textAlign="right">Name</Typography>
                </Stack>
            </Box>
            <Box  sx={{
                gridColumnStart: {xs: 3, sm: 4},
                gridColumnEnd: {xs: 12, sm: 12},
                gridRowStart: `1`,
                gridRowEnd: `1`,
                display: 'flex',
                direction: 'row',
            }}>
                <Stack direction="column-reverse" sx={{ flexGrow: 1, flexShrink: 1, width: '100%'}}>
                    <Typography key="pfsd" component="p" textAlign="left">Full stack development</Typography>
                    <Typography key="plsd" component="p" textAlign="left">Lead Software Developer</Typography>
                    <Typography key="pdt" component="p" textAlign="left">Daniel Taylor</Typography>
                </Stack>
            </Box>
            <Sheet sx={{
                borderStyle: 'dashed',
                gridColumnStart: `1`,
                gridColumnEnd: `12`,
                gridRowStart: `2`,
                gridRowEnd: `7`,
                overflowY: `scroll`,
                borderWidth: '1px',

                display: 'grid',
                gridTemplateColumns: {md: `repeat(2, 1fr)`, xs: 1},
                gridTemplateRows: {xs: `repeat(2, 1fr)`, md: 1},
            }}>
                <Box sx={{
                    gridColumnStart: 1,
                    gridColumnEnd: {xs: 12, md: 1},
                    gridRow: `1`,
                    height: `100%`,
                    width: `98%`,
                }}>
            <JuiMarkdown>
{`### Career Summary

* Polyglot, full-stack software developer.
* Actively engages in opportunities to mentor developers, and approaches code and design reviews to learn as much as to review.
* Approaches problems with a quality driven mindset.
* Extensive experience in:
    * Golang
    * Java
    * Typescript & JavaScript
    * Cloud infrastructure & deployments
    * Web service development
    * Command line utilities / application development
    * Developer tools development
* "Think of the solution; not the problem" is my default mindset

### Development Philosophy

* I strongly believe in Test Driven Development (TDD), but attention has to be paid to overhead costs.
* Properly implemented, Behaviour Driven Development (BDD) can greatly accelerate development and validation.
* Agile or waterfall can work when utilized in a manner which befits their strengths.
* The right tool for the right job.

### Recent Successes

1. Architected a build system for Arctic Wolf using shell scripting and available command line utilities with GitHub Actions.
    * Improved developer efficiency by ~ 10% through reduction of steps in work and automation of semi-rare commands to prevent confusing issues.
    * Reduced developer cognitive load by ejecting team projects from the old proprietary AWN build setup and using standard tools and methodologies.
    
2. Addressed inefficiencies in developer processes through Hackathon projects at Arctic Wolf.
    * Conceived and led the implementation of an automated system of transferring documentation from GitHub repositories to confluence adopted by ~5% of AWN teams.
        * Allowed for ensuring documentation updates occurred alongside code changes and avoiding stale confluence documentation.
    * Conceived and led the implementation of a command line replacement for Postman which could be used by developers for various kinds of manual and automated testing.
        * Included options to import Postman collections, supply auth headers, etc.

3. Revolutionized Digital 14 / Dark Matters deployment for their suite of communications tools.
    * Utilized Terraform, Docker, Kubernetes, Kubernetes Operators and Golang to migrate a bare metal on prem solution to be cloud based.
    * Worked with Google Cloud Platform (GCP), RedHat OpenShift and Amazon Web Services.
    * Architected the solution and led the implementation team consisting initially of two co-ops and ending with two co-ops and two full time resources.


`}
</JuiMarkdown>
</Box>
                <Box sx={{
                    gridColumnStart: {md: `2`, xs: `1`},
                    gridColumnEnd: {xs: 12, md: 12},
                    gridRow: {md: `1`, xs: `2`},
                    height: `100%`,
                    width: `98%`,
                }}>
<JuiMarkdown>
{`### Projects & Development Outside of Work

| Name | Description | Status / Version|
|---|---|---|
| _Redacted_ | Opinionated web server for Golang | pre-release |
| [Go Burn Bits](https://github.com/mortedecai/gbb) | Tools to help with the local development and upload of files for the BitBurner game | Alpha |
| [daniel-taylor.dev](https://daniel-taylor.dev) | This website written using the _Redacted_ server in Go for the back end and React for the front end. | |

### Interests Outside of Coding

#### Sports & Recreational Activities 

* Novice pool player learning in downtown Kitchener.
* Poor softball player, always looking for a summer team.
* Excellent fourth to make you feel better about your golf game.

#### Other Interests

* Video Games including more recently:
    * Diablo IV
    * Black Ops 6
    * Sid Meier's Civilization 6
    * Star Wars Outlaws

* Reading, both fiction and non-fiction:
    * The Reacher Series
    * The Wheel of Time Series
    * Pragmatic Programmer
    * Greek Mythology
    
 
`}
                </JuiMarkdown>
                </Box>
            </Sheet>
        </Sheet>
    );
};

export default ProfileView;