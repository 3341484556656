import React from 'react'
import Sheet from '@mui/joy/Sheet'
import {faStar, faStarHalfStroke} from "@fortawesome/sharp-duotone-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ISkillData} from './SkillBoard'

export interface ISelfRating {
    skill: ISkillData
}

const SelfRating: React.FC<ISelfRating> = (props: ISelfRating) => {
    return (
        <Sheet
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5,
            }}
            variant="soft">
            <FontAwesomeIcon icon={props.skill.selfRating === 4.5 ? faStarHalfStroke : faStar} style={{color: props.skill.selfRating > 4.0 ? "gold" : "#bed1ee"}} />
            <FontAwesomeIcon icon={props.skill.selfRating === 3.5 ? faStarHalfStroke : faStar} style={{color: props.skill.selfRating > 3.0 ? "gold" : "#bed1ee"}} />
            <FontAwesomeIcon icon={props.skill.selfRating === 2.5 ? faStarHalfStroke : faStar} style={{color: props.skill.selfRating > 2.0 ? "gold" : "#bed1ee"}} />
            <FontAwesomeIcon icon={props.skill.selfRating === 1.5 ? faStarHalfStroke : faStar} style={{color: props.skill.selfRating > 1.0 ? "gold" : "#bed1ee"}} />
            <FontAwesomeIcon icon={props.skill.selfRating === 0.5 ? faStarHalfStroke : faStar} style={{color: props.skill.selfRating > 0.0 ? "gold" : "#bed1ee"}} />
            <Sheet sx={{
                display: 'flex',
                flexGrow: 1,
            }}></Sheet>
        </Sheet>
    );
};

export default SelfRating;