import React, {useState, useEffect} from 'react'
import Sheet from '@mui/joy/Sheet'
import Chip from '@mui/joy/Chip'
import {ISkillData} from './SkillBoard'

export interface ISkillCategories {
    skill: ISkillData
}

const SkillCategories: React.FC<ISkillCategories> = (props: ISkillCategories) => {
    const [categories, setCategories] = useState<string[]>([]);

    useEffect(() => {
        let categories: string[] = props.skill.key.split(".")
        categories.pop()
        setCategories(categories)
    }, [props])

    return (
        <Sheet>
            {categories.map((value, index) => {return (<Chip key={index} color="success" size="sm">{value}</Chip>)})}
        </Sheet>
    );
};

export default SkillCategories;
