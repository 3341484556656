import React, { useCallback, useEffect, useState}  from 'react'

import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import { JuiMarkdown } from 'jui-markdown';

export interface INewsArticleID {
        id: number
        newsPath: string
    };

export interface INewsArticle {
    id: number;
    published: string;
    category: string;
    text: string;
    title: string;
}

const NewsArticle: React.FC<INewsArticleID> = (item: INewsArticleID) => {

    const [article, setArticle] = useState<INewsArticle>({id: -1, published: "", category: "", text: "", title: ""});

    const fetchNewsArticle = useCallback(async () => {
        const artPath = item.newsPath + (item.newsPath[item.newsPath.length-1] !== '/' ? '/' : '') + item.id
        const res : Response = await fetch(artPath);
        const story : INewsArticle = await res.json();
        setArticle(story);

    }, [item.id, item.newsPath])

    useEffect(() => {
        fetchNewsArticle();
    }, [fetchNewsArticle, item.id]);

    return (
        <Card
            orientation="horizontal"
            sx={{ bgcolor: 'background.surface', borderRadius: 0.75, mb: 1, border: 'gray', boxShadow: 'none' }}
        >
            <CardContent>
                <JuiMarkdown>{article.text}</JuiMarkdown>
            </CardContent>
        </Card>
    );

}

export default NewsArticle;

